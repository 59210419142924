// extracted by mini-css-extract-plugin
export var barHeading = "UserProjectProviders__barHeading__RuAwF";
export var column = "UserProjectProviders__column__jWYri";
export var container = "UserProjectProviders__container__aZAGO";
export var contentWrapper = "UserProjectProviders__contentWrapper__e7_x7";
export var helpText = "UserProjectProviders__helpText__pLdNP";
export var linkContainer = "UserProjectProviders__linkContainer__IoeFw";
export var linkContainerList = "UserProjectProviders__linkContainerList__l0mQ0";
export var linkCounter = "UserProjectProviders__linkCounter__OBx0d";
export var providerPanelContainer = "UserProjectProviders__providerPanelContainer__dow5N";
export var providersHeading = "UserProjectProviders__providersHeading__YJCk9";
export var row = "UserProjectProviders__row__jQ4HA";
export var title = "UserProjectProviders__title__Wohrn";
export var titleMain = "UserProjectProviders__titleMain__ZtmTL";
export var underlinedLink = "UserProjectProviders__underlinedLink__wnRRY";