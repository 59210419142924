// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import Format from "date-fns/format";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectProviderPanelScss from "./ProjectProviderPanel.scss";
import * as ProjectProviderStatusButton from "./ProjectProviderStatusButton.res.js";

var css = ProjectProviderPanelScss;

function ProjectProviderPanelBody(props) {
  var projectProvider = props.projectProvider;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("table", {
                      children: [
                        JsxRuntime.jsxs("colgroup", {
                              children: [
                                JsxRuntime.jsx("col", {
                                      width: "20%"
                                    }),
                                JsxRuntime.jsx("col", {
                                      width: "60%"
                                    }),
                                JsxRuntime.jsx("col", {
                                      width: "20%"
                                    })
                              ]
                            }),
                        JsxRuntime.jsx("thead", {
                              children: JsxRuntime.jsxs("tr", {
                                    children: [
                                      JsxRuntime.jsx("td", {
                                            children: JsxRuntime.jsx("p", {
                                                  children: "Date"
                                                }),
                                            className: css.providerTableHeadingContainer
                                          }),
                                      JsxRuntime.jsx("td", {
                                            children: JsxRuntime.jsx("p", {
                                                  children: "Event"
                                                }),
                                            className: css.providerTableHeadingContainer
                                          }),
                                      JsxRuntime.jsx("td", {
                                            children: JsxRuntime.jsx("p", {
                                                  children: "User"
                                                }),
                                            className: css.providerTableHeadingContainer
                                          })
                                    ]
                                  }),
                              className: css.providerTableHead
                            }),
                        JsxRuntime.jsx("tbody", {
                              children: projectProvider.events.length === 0 ? JsxRuntime.jsxs("tr", {
                                      children: [
                                        JsxRuntime.jsx("td", {
                                              children: JsxRuntime.jsx("p", {
                                                    children: Format(new Date(), "MM/dd/yyyy hh:mm aaaa")
                                                  }),
                                              className: css.providerTitle
                                            }),
                                        JsxRuntime.jsx("td", {
                                              children: JsxRuntime.jsx("p", {
                                                    children: "No changes have been made to this record."
                                                  }),
                                              className: css.eventInfo
                                            }),
                                        JsxRuntime.jsx("td", {
                                              children: JsxRuntime.jsx("p", {
                                                    children: "System User"
                                                  }),
                                              className: css.eventInfo
                                            })
                                      ],
                                      className: css.providerDetailRow
                                    }) : Belt_Array.mapWithIndex(projectProvider.events, (function (index, $$event) {
                                        return JsxRuntime.jsxs("tr", {
                                                    children: [
                                                      JsxRuntime.jsx("td", {
                                                            children: JsxRuntime.jsx("p", {
                                                                  children: Format($$event.date, "MM/dd/yyyy hh:mm aaaa")
                                                                }),
                                                            className: css.providerTitle
                                                          }),
                                                      JsxRuntime.jsx("td", {
                                                            children: JsxRuntime.jsx("p", {
                                                                  children: $$event.event
                                                                }),
                                                            className: css.eventInfo
                                                          }),
                                                      JsxRuntime.jsx("td", {
                                                            children: JsxRuntime.jsx("p", {
                                                                  children: $$event.user
                                                                }),
                                                            className: css.eventInfo
                                                          })
                                                    ],
                                                    className: css.providerDetailRow
                                                  }, String(index));
                                      }))
                            })
                      ],
                      className: css.providerTable
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(ProjectProviderStatusButton.make, {
                            projectProviderId: projectProvider.id,
                            reviewStatus: props.reviewStatus,
                            setReviewStatus: props.setReviewStatus
                          }),
                      className: css.providerStatusButtonContainer
                    })
              ]
            });
}

var make = ProjectProviderPanelBody;

export {
  css ,
  make ,
}
/* css Not a pure module */
